<template>
  <footer class="footer" id="footer" v-bind="this.dataFooter()" noprint>
    <div class="footer-wrap" v-if="this.info">
      <div class="main">
        <div class="footer-content primary-color">
          <div class="footer-info">
            <div class="footer-info-left">
              <div class="footer-left">
                <div class="footer-info-title">
                  <h2 class="third-font">{{ this.info.title }}</h2>
                </div>
                <div class="footer-buttons">
                  <span>
                    <router-link class="footer-button" :to="{ path: '/contact', hash: '#contact' }">
                      {{ this.info.contact }}
                      <img class="footer-buttons-img" src="../../assets/img/icons/arrow.svg" :alt="altArrow" />
                    </router-link>
                  </span>
                  <span>
                    <router-link class="footer-button" :to="{ path: '/jobs', hash: '#jobs' }">
                      {{ this.info.jobs }}
                      <img class="footer-buttons-img" src="../../assets/img/icons/arrow.svg" :alt="altArrow" />
                    </router-link>
                  </span>
                  <span>
                    <router-link class="footer-button" :to="{ path: '/help', hash: '#help' }">
                      {{ this.info.faq }}
                      <img class="footer-buttons-img" src="../../assets/img/icons/arrow.svg" :alt="altArrow" />
                    </router-link>
                  </span>
                </div>
              </div>
              <div>
                <div class="footer-social-label">
                  <span>{{ this.info.socialText }}</span>
                </div>
                <div class="social-icons">
                  <template v-for="(social, i) in info.social_media">
                    <div class="social-icon" v-if="social.url" @click="sanitizeUrl(social.url)" :key="i">
                      <iconic :svgCode="social.icon" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <hr class="footer-line" />
            <div class="footer-cities-copyr">
              <div class="footer-cities">
                <span capitalize>{{ this.info.cities.toLowerCase() }}</span>
              </div>
              <div class="footer-copyr">
                <span>{{ `${this.info.copyRight.split("20", 1).join(" ")}  ${new Date().getFullYear()}` }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import store from "../../store/index.js";
import Iconic from "@/plugins/iconic/iconic.vue";
export default {
  components: {
    Iconic,
  },
  name: "Footer",
  data() {
    return {
      info: null,
      altArrow: "Flecha de redireccion para mas informacion."
    };
  },
  methods: {
    dataFooter: async function () {
      if (store.getters["iflanguage"]) {
        let dataLang = await store.getters["dataLang"];
        this.info = dataLang.footer;
        this.info["social_media"] = this.$global.sortSocialMedia(dataLang.resource.social_media);
      }
    },
    sanitizeUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";

.footer {
  &-wrap {
    background-color: $footer_bg;
    background-color: $primary_color;
    background: linear-gradient(1.47deg, #a30000 9.48%, #c7062e 98.35%);
    padding: 17px $mpadding;
    text-align: center;
  }
  &-info {
    &-title {
      color: $footer_bg;
      margin-bottom: $mpadding/4;
      .third-font {
        font-size: 20px;
      }
    }
  }
  .footer-buttons {
    width: 100%;
    color: $footer_bg;
    margin: 0 auto;
    span {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .footer-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        font-size: 14px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-left: 10px;
        border: 1px solid $white;
        padding: 6px 4.5px;
        border-radius: 50%;
      }
    }
  }
  &-cities {
    margin-bottom: 10px;
    color: $footer_bg;
    span {
      font-size: 11px;
    }
  }
  .footer-social-label {
    color: $footer_bg;
    margin: 7px 0;
    span {
      font-size: 14px;
    }
  }
  &-line {
    width: 90%;
    margin: 15px auto 10px auto;
  }
  &-social-icons {
    margin-bottom: 7px;
  }
  &-social-icon {
    position: relative;
    width: 25px;
    height: 25px;
    line-height: 22px;
    background: $white;
    color: $primary_color;
    display: inline-block;
    border-radius: 50%;
    margin: 0 4px;
    text-align: center;
    transition: 0.3s ease-in-out;

    &:hover {
      background: $primary_color_hover;
    }
    .iconic,
    .footer-social-personalize {
      position: relative;
    }
    .footer-social-personalize {
      font-size: 13px;
    }
  }
  &-copyr {
    color: $footer_bg;
    span {
      font-size: 10px;
    }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 0;
    margin: 10px 0;
    .social-icon {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      background-color: white;
      color: #a30000;
      padding: 3px;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.18);
    }
  }

  @media (min-width: $tablet_width) {
    .footer-info {
      display: flex;
      justify-content: space-between;
      align-content: center;
      flex-direction: column;
    }
    .footer-line {
      display: none;
    }
    .footer-info-title {
      text-align: left;
    }
    .footer-info-left {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 90%;
      padding-bottom: 5px;
      margin: 0 auto;
      border-bottom: 1px solid $footer_bg;
    }

    .footer-buttons {
      width: 210px;
      justify-content: flex-start;
      span {
        width: 100%;
        margin: 0;
        justify-content: flex-start;
      }
    }
    .footer-cities-copyr {
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin: 0 auto;
      width: 90%;
      margin-top: 10px;
    }
    .social-icons {
      display: flex;
      justify-content: right;
    }
  }
}
</style>
