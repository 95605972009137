<template>
  <div class="instagram-btn transition" noprint>
    <a class="instagram-btn-buttom" target="_blank" href="https://instagram.com/models1a.agency?igshid=YmMyMTA2M2Y=">
      <img class="instagram-btn-img" src="https://new-web-site-bucket.s3.amazonaws.com/website/networks/Instagram%20%281%29.png" alt="Boton redireccion a Instagram" />
    </a>
  </div>
</template>

<script>
  import iconic from "@/plugins/iconic/iconic.vue";

  export default {
    components: { iconic },
  };
</script>

<style lang="scss">
  .instagram {
    &-btn {
      &-img {
        width: 50px;
        height: 50px;
        margin-right: 8px;
      }
    }
  }
</style>
