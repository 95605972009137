<template>
  <div :class="['button-radar-btn ', classbutton]">
    <div class="btn">
      <div :class="'radar ' + classbutton"></div>
      <div :class="'radar ' + classbutton"></div>
      <div :class="'radar ' + classbutton"></div>
      <a :class="'iconRadar ' + classbutton" :href="refto">
        <img class="btn__img" src="https://new-web-site-bucket.s3.amazonaws.com/website/networks/SUP-2.gif" :alt="localeData.alt.gif" />
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import iconic from "@/plugins/iconic/iconic.vue";

  export default {
    props: {
      refto: String,
      icon: String,
      iconic: String,
      classbutton: {
        default: "",
      },
    },
    components: { iconic },
    computed : {
      ...mapGetters(["newLocale"]),
      localeData(){
        return this.newLocale
      },
    }
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .button-radar {
    &-btn {
      display: block;
      width: 55px;
      height: 55px;
      top: 55px;
      margin-right: 23px;
      position: absolute;
      right: 8%;
      margin-top: -50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      text-align: center;

      &.small {
        width: 80px;
        height: 80px;
        margin-top: -75px;
        right: 32px;
      }
      &.register {
        position: fixed;
        z-index: 3;
        bottom: 148px;
        right: -17px;
      }
      .iconRadar {
        position: absolute;
        width: 55px;
        height: 55px;
        background-color: $primary_color;
        border-radius: 50%;
        color: $white;
        cursor: pointer;
        display: inline-block;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 16px;
        text-align: center;
        z-index: 1;
        &.small {
          width: 30px;
          height: 30px;
          padding: 0;
          line-height: 30px;
        }
        &.register {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 44px;
          height: 44px;
          padding: 0;
          line-height: 30px;
          font-size: 200%;
          box-shadow: 0 0 2px $black;
        }
        & > .small {
          margin-left: 3px;
        }
        & > .register {
          margin-bottom: 2px;
          margin-left: 4px;
        }
      }
      .radar {
        position: absolute;
        border-radius: 50%;
        border: 10px solid #ce1718;
        width: 150px;
        height: 150px;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: radar 2s infinite;
        opacity: 0;
        &.small {
          animation: radar-small 2s infinite;
          width: 10px;
          height: 10px;
        }
        &:nth-child(2) {
          -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
        }
        &:nth-child(3) {
          -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
        }
        &.register {
          animation: radar-register 2s infinite;
          width: 10px;
          height: 10px;
          box-shadow: 0 0 3px $black;
          &:nth-child(2) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
          }
          &:nth-child(3) {
            -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
          }
          &--isActiveHome {
            display: none;
          }
        }
      }
    }
    @media (min-width: $tablet_width) {
      &-btn {
        display: none;
        margin-top: -25px;
      }
      &-btn {
        &.register {
          display: block;
          bottom: 110px;
          right: -15px;
          .btn__img {
            position: absolute;
            top: 7px;
            left: 10px;
          }
        }
        .iconRadar {
          &.register {
            width: 50px;
            height: 50px;
            font-size: 260%;
          }
          & > .register {
            margin-left: 0px;
            margin-bottom: 0px;
          }
        }
        .radar {
          &.register {
            animation: radar-registerDesktop 2s infinite;
            &--isActiveHome {
              display: block;
            }
          }
          &:nth-child(2) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
          }
          &:nth-child(3) {
            -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
          }
        }
      }
    }
  }
  .btn {
    &__img {
      position: absolute;
      top: 3px;
      left: 7px;
      width: 35px;
      height: 35px;
    }
  }
</style>
